import React from 'react';
import { Article } from './magazine_section';

const MagazineArticle: React.FC<{ article: Article }> = ({ article }) => {
  return (
    <article className="c-magArticle">
      <a className="c-magArticle__link" href={article.link}>
        <h3 className="c-magArticle__title">{article.title}</h3>
        <figure className="c-magArticle__figure">
          <img className="c-magArticle__image" src={article.thumbnail} alt="" width={300} height={169} />
        </figure>
      </a>
    </article>
  );
};

export default MagazineArticle;
