import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';

import { createRoot } from 'react-dom/client';

import MagazineArticle from './magazine_article';

export type Article = {
  title: string;
  link: string;
  thumbnail: string;
};

type Props = {
  endpoint: string;
  title: string;
  service?: string;
};

const magazineSection = document.getElementById('magazine-section');

const MagazineSection: React.FC<Props> = ({ endpoint, title, service }) => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    let url = endpoint;
    if (service != null) {
      url = `${url}?service=${service}`;
    }

    axios.get(url).then((res: AxiosResponse<Article[]>) => {
      const { data } = res;
      setArticles(data);
    });
  }, []);

  if (articles.length === 0 || articles.filter(a => a.link || a.title || a.thumbnail).length === 0) {
    magazineSection.classList.add('is-hidden');
    return <></>;
  }

  magazineSection.classList.remove('is-hidden');

  return (
    <>
      <div className="c-heading2 -mb20">
        <h2 className="c-heading2__title">{title}</h2>
      </div>
      <div className="p-magazine__articles">
        {articles.map((article, index) => {
          return <MagazineArticle key={index} article={article} />;
        })}
      </div>
      <div className="p-magazine__textlink">
        <div className="c-link">
          <a className="c-link__text" href="https://sumutasu.jp/mag">
            さらに見る ＞
          </a>
        </div>
      </div>
    </>
  );
};

const reactProps = JSON.parse(magazineSection.dataset.reactProps);
const section = createRoot(magazineSection);
section.render(<MagazineSection endpoint={reactProps.endpoint} title={reactProps.title} />);
